






































import { DTO } from '@/interfaces/DTO';
import Vue from 'vue';
import Component from 'vue-class-component';
import Vuelidate from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { namespace } from 'vuex-class';

const Auth = namespace('Auth');

Vue.use(Vuelidate);

@Component({
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
})
export default class Login extends Vue {
  form = { email: '', password: '' };
  loading: boolean = false;
  message: string = '';

  @Auth.Getter
  private isLoggedIn!: boolean;

  @Auth.Action
  private login!: (data: DTO.login.Request) => Promise<DTO.login.Response>;

  created() {
    if (this.isLoggedIn) {
      this.$router.push('/timers');
    }
  }

  getValidationClass(fieldName: string) {
    const field = this.$v.form[fieldName];

    if (field) {
      return {
        'md-invalid': field.$invalid && field.$dirty,
      };
    }
  }

  validateForm() {
    this.$v.$touch();

    if (!this.$v.$invalid) {
      this.handleLogin();
    }
  }

  private handleLogin() {
    this.loading = true;
    this.$v.$touch();

    if (!this.$v.$invalid) {
      if (this.form.email && this.form.password) {
        this.login(this.form).then(
          (data) => {
            if (data.token) {
              this.$router.push('/timers');
              return;
            }
            throw new Error();
          },
          (err) => {
            console.error(err);
            this.loading = false;
            this.message = 'Login error';
          },
        );
      }
    }
  }
}
